import './App.css';
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import PersonalAccount from "../PersonalAccount/PersonalAccount";
import Payment from "../Payment/Payment";
import ParentalControl from "../ParentalControl/ParentalControl";
import IntercomEvents from "../IntercomEvents/IntercomEvents";

function App() {
    return (
        <Router>
            <div className="app">
                <Routes>
                    <Route exact path='/' element={<PersonalAccount/>}/>
                    <Route exact path='/profile' element={<PersonalAccount/>}/>
                    <Route exact path='/payment' element={<Payment/>}/>
                    <Route exact path='/parentalControl' element={<ParentalControl/>}/>
                    <Route exact path='/intercomEvents' element={<IntercomEvents/>}/>
                </Routes>
            </div>
        </Router>
    );
}

export default App;
