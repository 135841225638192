import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useHttp } from "../../utils/services/useHttp";
// import { useHttpTest } from "../../utils/services/useHttpTest";

const initialState = {
    loadingParentalControl: 'idle',
    loadingCheckPayment: 'idle',
    orderId: null,
    formUrl: null,
    list: []
};

export const fetchDoParentalControl = createAsyncThunk(
    'parental/fetchDoParentalControl',
    (data) => {
        const { request } = useHttp();
        return data
    }
)

export const fetchSetParentalScheduler = createAsyncThunk(
    'parental/fetchSetParentalScheduler',
    (data) => {
        const { request } = useHttp();
        return  request('/SetParentalScheduler', data)
        // return data
    }
)

export const fetchGetParentalScheduler = createAsyncThunk(
    'parental/fetchGetParentalScheduler',
    (data) => {
        const { request } = useHttp();
        return  request('/GetParentalScheduler', data)
        // return data
    }
)

const parentalControlSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDoParentalControl.pending, state => {state.loadingParentalControl = 'loading'})
            .addCase(fetchDoParentalControl.fulfilled, (state, action) => {
                state.list = action.payload.data;
                // if(action.payload.index !== null){
                //     state.list[action.payload.index].status = !action.payload.data[action.payload.index].status
                // }

                state.loadingParentalControl = 'idle';
                return state;
            })
            .addCase(fetchDoParentalControl.rejected, state => {state.loadingParentalControl = 'error'})

            .addCase(fetchSetParentalScheduler.pending, state => {state.loadingParentalControl = 'loading'})
            .addCase(fetchSetParentalScheduler.fulfilled, (state, action) => {
                state.loadingParentalControl = 'idle';
                return state;
            })
            .addCase(fetchSetParentalScheduler.rejected, state => {state.loadingParentalControl = 'error'})

            .addCase(fetchGetParentalScheduler.pending, state => {state.loadingParentalControl = 'loading'})
            .addCase(fetchGetParentalScheduler.fulfilled, (state, action) => {
                state.list = action.payload.Schedule.schedule ? action.payload.Schedule.schedule : [];
                state.loadingParentalControl = 'idle';
                return state;
            })
            .addCase(fetchGetParentalScheduler.rejected, state => {state.loadingParentalControl = 'error'})
    }
});

const {actions, reducer} = parentalControlSlice;

export default reducer;