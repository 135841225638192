import React, { useEffect, useState } from "react";
import './personalAccount.css';
import bellSVG from "../../assets/vectors/bell.svg";
import refreshSVG from "../../assets/vectors/refresh.svg";
import smartSVG from "../../assets/vectors/smart.svg";
import inetSVG from "../../assets/vectors/inet.svg";
import ktvSVG from "../../assets/vectors/ktv.svg";
import videoSVG from "../../assets/vectors/video.svg";
import phoneSVG from "../../assets/vectors/phone.svg";
import loader from "../../assets/loader.gif"
import {useDispatch, useSelector} from "react-redux";
import successfullySVG from "../../assets/vectors/successfully.svg";
import image from "../../assets/images/image.png";

import {
    fetchGetTokens,
    fetchMainCustomerServiceInfo,
    GetCurrentCustomersWithAddress,
    availableInternetConnections,
    CheckPromised,
    GetPromised
} from "./PersonalAccountSlice";
import {useNavigate} from "react-router-dom";

export default function PersonalAccount() {
    const [modalState, setModalState] = useState(false);
    const [modalPaymentState, setModalPaymentState] = useState(false);
    const [modalPromisedPay, setModalPromisedPay] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const personalAccountSlice = useSelector(state => state.personalAccountSlice);
    const customers = useSelector(state => state.personalAccountSlice.customers);
    const connectedServices = useSelector(state => state.personalAccountSlice.connectedServices);
    const [currentCustomer, setCurrentCustomer] = useState(null);
    const [currentAddress, setCurrentAddress] = useState(null);
    const url = new URL(window.location);

    // useEffect(() => {
    //     document.cookie = "msid=" + url.searchParams.get('sip')
    // }, [])

    useEffect(() => {
        if(url.searchParams.get('status') === "success") {
            setModalPaymentState(true);
            setTimeout(() => {
                setModalPaymentState(false);
            }, 3000)
        } else if (url.searchParams.get('status') === "failure") {
            alert("Ошибка")
        }
    }, [url.searchParams.get('status')])


    useEffect(() => {
        // if (url.searchParams.get('sip')) {
            const token = {
                token: url.searchParams.get('sip') !== 'null' ? url.searchParams.get('sip') : null
            }
            dispatch(fetchGetTokens(token));
        // }

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (personalAccountSlice.token === '') {
            dispatch(GetCurrentCustomersWithAddress());
        }
        // eslint-disable-next-line
    }, [personalAccountSlice.token]);

    useEffect(() => {
        if(!currentCustomer) {
            setCurrentCustomer(customers[0])
        }
        // eslint-disable-next-line
    }, [customers]);

    useEffect(() => {
        if(currentCustomer) {
            const data = {
                customerId: currentCustomer.customerId
            }
            setCurrentAddress(currentCustomer.address);
            dispatch(fetchMainCustomerServiceInfo(data));
            dispatch(availableInternetConnections(data));
            dispatch(CheckPromised(data))
        }

        // eslint-disable-next-line
    }, [currentCustomer]);

    const Spinner = () => {
        return (
            <div className="loading__spinner">
                <img src={loader} alt='загрузка'/>
            </div>
        )
    };

    const getServiceName = (elem) => {
        const data = {
            customerId: currentCustomer ? currentCustomer.customerId : null,
            currentAddress: currentAddress ? currentAddress : null,
            serviceId: null,
            name: null,
            svg: null,
            button1: null,
            button2: null,
            type: null,
            elem: null,
            t: url.searchParams.get('sip')
        }

        switch (elem.type) {
            case "INTERNET":
                data.name = "Интернет";
                data.svg = inetSVG;
                data.serviceId = 1;
                data.elem = elem
                // data.button1 = {
                //     text: 'Родительский контроль',
                //     href: '/parentalControl',
                //     class: ''
                // };
                data.button2 = {
                    text: 'Оплатить',
                    href: '/payment',
                    class: 'active'
                };
                data.button3 = {
                    text: 'Доверительный платеж',
                    href: '',
                    class: '',
                };
                break;
            case "KTV":
                data.name = "Телевидение";
                data.svg = ktvSVG;
                data.serviceId = 2;
                data.elem = elem
                data.button1 = {
                    text: 'Оплатить',
                    href: '/payment',
                    class: 'active'
                };
                break;
            case "PHONE":
                data.name = "Телефон";
                data.svg = phoneSVG;
                data.serviceId = 3;
                data.elem = elem
                data.button1 = {
                    text: 'Оплатить',
                    href: '/payment',
                    class: 'active'
                };
                break;
            case "INTERCON":
                data.name = "Домофон";
                data.svg = smartSVG;
                data.serviceId = 4;
                data.elem = elem
                data.button1 = {
                    text: 'Журнал',
                    href: '/intercomEvents',
                    class: 'active'
                };
                data.button2 = {
                    text: 'Оплатить',
                    href: '/payment',
                    class: 'active'
                };
                break;
            case "VIDEO":
                data.name = "Облачное видеонаблюдение";
                data.svg = videoSVG;
                data.serviceId = 1;
                data.elem = elem
                data.button1 = {
                    text: 'Посмотреть камеры',
                    href: '',
                    class: ''
                };
                data.button2 = {
                    text: 'Оплатить',
                    href: '/payment',
                    class: 'active'
                };
                break;
        }
        return data;
    }

    const goTo = (href, data) => {
        if(href) {
            navigate(href, {state: data})
        }
    }

    const serviceBlock = () => {
        if (personalAccountSlice.loadingMainCustomerServiceInfo === 'loading') {
            return (
                <div className="personal__account_services">
                    <Spinner/>
                </div>
            )
        } else if (personalAccountSlice.loadingMainCustomerServiceInfo === 'idle') {
            return  connectedServices.map((e,i) => {
                const serviceName = getServiceName(e);

                return (
                    <div className="personal__account_services" key={serviceName.name}>
                        <div className="left_block">
                            <div className="first_row">
                                <img src={serviceName.svg}/>
                                <h4>
                                    {serviceName.name}
                                </h4>
                            </div>
                            <div className="second_row">
                                <h4>{e.tariffs[0] ? e.tariffs[0].name.replaceAll('_', ' ') : ''}</h4>
                            </div>
                            <div className="second_row">
                                Баланс:&nbsp;<h4>{(typeof e.balance) === 'number' ? e.balance : +(e.balance.replace(',', '.'))} ₽</h4>
                            </div>
                            <div className="second_row">
                                К оплате:&nbsp;<h4>{+e.recommendedPay} ₽</h4>
                            </div>
                        </div>
                        <div className="right_block">
                            {serviceName.button1 ?
                                <button className={serviceName.button1.class ? serviceName.button1.class : ''}
                                        onClick={() => goTo(serviceName.button1.href, serviceName)}>{serviceName.button1.text}</button> : null}
                            {serviceName.button2 ?
                                <button className={serviceName.button2.class ? serviceName.button2.class : ''}
                                        onClick={() => goTo(serviceName.button2.href, serviceName)}>{serviceName.button2.text}</button> : null}
                            {serviceName.button3 ?
                                <button className={serviceName.button3.class ? serviceName.button3.class : ''}
                                        onClick={() => setModalPromisedPay(true)}>{serviceName.button3.text}</button> : null}
                        </div>
                    </div>
                )
            })
        }
    }

    const AddressBlock = () => {
        return (
            customers.map((elem, i) => {
                return (
                    <li className={ elem.customerId === (currentCustomer ? currentCustomer.customerId : null) ? "active" : ""} key={elem.customerId}
                        onClick={() => {
                            setCurrentCustomer(elem);
                            setCurrentAddress(elem.address)
                        }}>
                        <h4>{elem.address}</h4>
                        <p>Лицевой счёт: {elem.customerId}</p>
                    </li>
                )
            })
        )
    }

    const SelectBlock = () => {
        return (
            <>
                <div className="select">
                    <span className="selected">
                        <h4>{currentCustomer ? currentCustomer.address : null}</h4>
                        <p>Лицевой счёт: {currentCustomer ? currentCustomer.customerId : null}</p>
                    </span>
                    <div className={`caret ${modalState ? 'rotate' : ''}`}></div>
                </div>
                <ul className={`menu ${modalState ? 'open' : ''}`}>
                    <AddressBlock/>
                </ul>
            </>
        )
    }

    const getAvailableInternetConnections = () => {
        if (personalAccountSlice.availableInternetConnections.state === 1) {
            return (
                <>
                    всё отлично
                </>
            );
        } else if (personalAccountSlice.availableInternetConnections.state === 0){
            return (
                <>
                    нет соединения
                </>
            );
        } else {
            return (
                <>
                    не установленно
                </>
            )
        }
    };

    const refreshBtn = () => {
        dispatch(GetCurrentCustomersWithAddress())
        if(currentCustomer) {
            const data = {
                customerId: currentCustomer.customerId
            }
            dispatch(GetCurrentCustomersWithAddress());
            dispatch(fetchMainCustomerServiceInfo(data));
            dispatch(availableInternetConnections(data));
            dispatch(CheckPromised(data))
        }
    }

    const PaymentSuccessfully = () => {
        return (
            <div>
                <img src={successfullySVG}/>
                <h2>Готово!</h2>
                <p>Ваш баланс пополнен</p>
            </div>
        )
    }

    const CustomerName = () => {
        if (currentCustomer) {
            return (
                <>
                    {personalAccountSlice.customerInfo[currentCustomer.customerId]}
                </>
            )
        } else {
            return (
                <>
                    Не найден ЛС
                </>
            )

        }
    }

    const getPromised = () => {
        const data = {
            "customerId": currentCustomer.customerId
        };
        setModalPromisedPay(() => !modalPromisedPay)
        dispatch(GetPromised(data));
    };

    const GetPromisedPay = () => {
        // if(!personalAccountSlice.promised.promiseAllow) {
        if(personalAccountSlice.promised.promiseAllow && personalAccountSlice.promised.promiseAmount > 0) {
            return (
                <>
                    <div style={{marginBottom: 30, fontSize: 20}}>
                        доступная сумма: {personalAccountSlice.promised.promiseAmount} ₽
                    </div>
                    <div>
                        <button
                            type="button"
                            onClick={ getPromised }>
                            Взять
                        </button>
                        <button
                            type="button"
                            onClick={() => setModalPromisedPay(() => !modalPromisedPay)}>
                            Назад
                        </button>
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <p style={{fontSize: 14, marginLeft: 15}}>
                        Вам не доступен "Доверительный платеж" <br/><br/>
                        Услуга может быть недоступна по любой из ниже приведенных причин: <br/><br/>

                        Задолженность по лицевому счету по услуге интернет превышает 100 рублей;<br/>
                        Услуга "Доверительный платеж" не доступна с 6 и до 24 числа включительно;<br/>
                        В предыдущем месяце вы не пользовались интернетом;<br/>
                        Вы уже воспользовались услугой "Доверительный платеж".
                    </p>
                    <button
                        type="button"
                        onClick={() => setModalPromisedPay(() => !modalPromisedPay)}>
                        Назад
                    </button>
                </>
            )
        }
    };

    return (
        <>
            <section className="personal__account">
                <div className="personal__account_block_info">
                    {/*<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                    {/*    <circle cx="16" cy="16" r="16" fill="#C4C4C4"/>*/}
                    {/*</svg>*/}
                    <h1>
                        <CustomerName/>
                    </h1>
                    {/*<img src={bellSVG}/>*/}
                </div>
                <div className="personal__account_block_status">
                    <div>
                        Состояние сети: {getAvailableInternetConnections()}
                    </div>
                    <img onClick={refreshBtn} src={refreshSVG}/>
                </div>
                <div className="personal__account_block_scroll">
                    {/*<div className="personal__account_tab">*/}
                    {/*    <p>Инсит-ТВ online</p>*/}
                    {/*</div>*/}
                    {/*<div className="personal__account_tab">*/}
                    {/*    <p>Теле программа</p>*/}
                    {/*</div>*/}
                    {/*<div className="personal__account_tab">*/}
                    {/*    <p>Видео камеры Копейска</p>*/}
                    {/*</div>*/}
                    {/*<div className="personal__account_tab">*/}
                    {/*    <p>Новости Копейска</p>*/}
                    {/*</div>*/}
                    {/*<div className="personal__account_tab">*/}
                    {/*    <p>Уличный Wi-Fi</p>*/}
                    {/*</div>*/}
                    {/*<div className="personal__account_tab">*/}
                    {/*    <p>Письмо деректору</p>*/}
                    {/*</div>*/}
                </div>

                <div className="personal__account_block_services">
                    {/*<>*/}
                    {/*    <div className="banner" style={{ backgroundImage:`url(${image})` }}>*/}
                    {/*        /!*<img src={image}/>*!/*/}
                    {/*    </div>*/}
                    {/*</>*/}

                    <div className="personal__account_dropdown" onClick={() => setModalState(() => !modalState)}>
                        {personalAccountSlice.loadingGetCurrentCustomersWithAddress === "loading" ?
                            <Spinner/> : <SelectBlock/>}
                    </div>
                    <div className={`menu_successfully ${modalPaymentState ? 'open' : ''}`} onClick={() => setModalPaymentState(() => !modalPaymentState)}>
                        <PaymentSuccessfully/>
                    </div>
                    <div className={`menu_successfully ${modalPromisedPay ? 'open' : ''}`}
                         // onClick={() => setModalPromisedPay(() => !modalPromisedPay)}
                         onClick={e => e.stopPropagation()}>
                        <GetPromisedPay/>
                    </div>
                    <div className={`background ${modalState || modalPaymentState || modalPromisedPay ? 'active' : ''}`}></div>
                    {serviceBlock()}
                </div>
            </section>
        </>
    )
}