import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import arrowLeftSVG from "../../assets/vectors/arrowLeft.svg";
import { fetchGetIntercomEvents } from "./IntercomEventsSlice";
import "./intercomEvents.css"
import loader from "../../assets/loader.gif";

export default function IntercomEvents () {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const data = useLocation();
    const elem = data.state ? data.state : [];
    const intercomEventsSlice = useSelector(state => state.intercomEventsSlice);

    const goTo = (href) => {
        navigate(href)
    }

    useEffect(() => {
        if(!data.state) {
            navigate('/profile')
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(elem) {
            const data = {
                customerId: elem.customerId
            }
            dispatch(fetchGetIntercomEvents(data));
        }
        // eslint-disable-next-line
    }, []);

    const Spinner = () => {
        return (
            <div className="loading__spinner">
                <img src={loader} alt='загрузка'/>
            </div>
        )
    };

    const EventData = () => {
        if ( intercomEventsSlice.loadingStatus === 'loading' ) {
            return (
                <div className="intercom_events__data">
                    <Spinner/>
                </div>
            )
        } else {
            if ( intercomEventsSlice.events.length ) {
                return (
                    intercomEventsSlice.events.map((elem, i) => {
                        return (
                            <div className="intercom_events__data">
                                <div>{elem.text}</div>
                                <div>{elem.date}</div>
                            </div>
                        )
                    })
                )
            } else {
                return (
                    <div className="intercom_events__data">
                        <div>Нет событий</div>
                    </div>
                )
            }

        }
    }

    return (
        <div className="intercom_events">
            <img className="arrowLeft" alt='Вернуться' onClick={() => goTo(`/profile?sip=${data.state.t}`)} src={arrowLeftSVG}/>
            <div className="intercom_events__info">
                <EventData/>
            </div>
        </div>
    )
}