import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useHttp } from "../../utils/services/useHttp";

const initialState = {
    events: [],
    loadingStatus: 'idle'
};

export const fetchGetIntercomEvents = createAsyncThunk(
    'intercomEvents/fetchGetIntercomEvents',
    (data) => {
        const { request } = useHttp();
        return  request('/GetIntercomEvents', data)
    }
)

const IntercomEventsSlice = createSlice({
    name: 'intercomEvents',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGetIntercomEvents.pending, state => {state.loadingStatus = 'loading'})
            .addCase(fetchGetIntercomEvents.fulfilled, (state, action) => {
                state.events = action.payload.events;
                state.loadingStatus = 'idle';
                return state;
            })
            .addCase(fetchGetIntercomEvents.rejected, state => {state.loadingStatus = 'error'})

            .addDefaultCase(() => {})
    }
});

const {actions, reducer} = IntercomEventsSlice;

export default reducer;