import React, { useEffect, useState } from "react";
import './parentalControl.css';
import arrowLeftSVG from "../../assets/vectors/arrowLeft.svg";
import editSVG from "../../assets/vectors/edit.svg";
import loader from "../../assets/loader.gif"
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {fetchDoParentalControl, fetchGetParentalScheduler, fetchSetParentalScheduler} from "./ParentalControlSlice";


export default function ParentalControl () {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const data = useLocation();
    const elem = data.state ? data.state : [];
    const [active, setActive] = useState(false);
    const [checkedParental, setCheckedParental] = useState(false);
    const [checkedPin, setCheckedPin] = useState(false);
    const [checked, setChecked] = useState(false);
    const [selectedList, setSelectedList] = useState(null);
    const [stateList, setStateList] = useState([]);

    const parentalSlice = useSelector(state => state.parentalControlSlice);
    const daysName = {
            1: 'Пн.',
            2: 'Вт.',
            3: 'Ср.',
            4: 'Чт.',
            5: 'Пт.',
            6: 'Сб.',
            7: 'Вс.',
        };

    useEffect(()=> {
        const data = {
            customerId: elem.customerId
        }

        dispatch(fetchGetParentalScheduler(data))
    }, [])

    useEffect(() => {

        setStateList(parentalSlice.list)

    }, [parentalSlice.list])

    const changeClick = (item, i) => {
        const newState = stateList.map((obj, ind) => {
            if (i === ind) {
                return {...obj, status: !obj.status};
            }

            return obj;
        });

        changeTime(true, newState)
    }

    const changeTime = (send, delData) => {
        const startTime = document.querySelector('#start_time').value;
        const endTime = document.querySelector('#end_time').value;
        const newState = delData ? delData : stateList.map((obj, ind) => {
            if(ind === selectedList){
                return {...obj, time_1: startTime, time_2: endTime}
            }

            return obj;
        });

        setStateList(newState);

        if(send === true) {
            const data = {
                customerId: elem.customerId,
                schedule: newState
            }
            dispatch(fetchSetParentalScheduler(data));
        }
    }

    useEffect(() => {
        const data = {
            customerId: elem.customerId
        }

        dispatch(fetchGetParentalScheduler(data))
    }, [])

    const TimeBlock = () => {
        return  stateList.map((item, index) => {
            const days = item.days.map((e)=> daysName[e])

            return (
                <div className="date_block" key={index}>
                    <label className="toggle" onTouchStart={e => e.preventDefault()}>
                        <span className="toggle-label">
                            {item.time_1} - {item.time_2}
                            <img className='edit_svg' onClick={()=> {
                                setSelectedList(index);
                                setChecked(state => !state);
                            }} src={editSVG}/>
                            <br/>
                            <p>
                                {days}
                            </p>
                        </span>
                        <input className="toggle-checkbox" type="checkbox"
                               // checked={item.status}
                               checked={stateList[index].status}
                               onChange={(e) => false}
                        />
                        <div className="toggle-switch"
                             onClick={(e) => changeClick(item, index)}
                        ></div>
                    </label>

                </div>
            )
        })
    }

    useEffect(() => {
        if(!data.state) {
            navigate('/profile')
        }
        // eslint-disable-next-line
    }, [])

    const goTo = (href) => {
        navigate(href)
    }

    const Time = () => {
        let date =  new Date(2000, 1, 1, 0, 0);
        const dateCombo = []
        for(let i = 0; i < 96; i++) {
            dateCombo.push(`${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${date.getMinutes() === 0 ? '00' : date.getMinutes()}`);
            date.setMinutes(date.getMinutes() + 15)
        }

        return (
                <>
                    <select defaultValue={stateList[selectedList] ? stateList[selectedList].time_1 : "9:00"} id='start_time' onChange={changeTime}>
                        {
                            dateCombo.map((item) => {
                                return (
                                    <option key={'start' + item} value={item}>
                                        {item}
                                    </option>
                                )
                            })
                        }
                    </select>
                    <select defaultValue={stateList[selectedList] ? stateList[selectedList].time_2 : "18:00"} id='end_time' onChange={changeTime}>
                        {
                            dateCombo.map((item) => {
                                return (
                                    <option key={'end' + item} value={item}>
                                        {item}
                                    </option>
                                )
                            })
                        }
                    </select>
                </>
            )
    }

    const changeDaysList = (elem) => {
        elem.target.classList.toggle('active');

        const newState = stateList.map((obj, ind) => {
            if(ind === selectedList){
                const btn = document.querySelectorAll('.button_day');
                let newArrDays = [];

                btn.forEach((elem, i )=> {
                    if (elem.classList.contains("active")) {
                        newArrDays.push(i+1)
                    }
                });

                newArrDays.sort()

                return {...obj, days: newArrDays}
            }

            return obj;
        });
        setStateList(newState);
    }

    const GetBtnDay = (i) => {
        const week = Array(7).fill(false);

        if(stateList[selectedList]) {
            stateList[selectedList].days.map((item) => {
                week[item - 1] = true;
            })
        }

        return  week.map((item, index) => {
            return (
                <button key={'button_day' + index}
                    className={`button_day ${week[index] ? 'active' : ''}`}
                    onClick={(e) => {changeDaysList(e)}}>
                    {daysName[index + 1]}
                </button>
            )
        })
    }

    return (
        <section className="parental__control">
            <img className="arrowLeft" alt='Вернуться' onClick={() => goTo(`/profile?sip=${data.state.t}`)} src={arrowLeftSVG}/>
            <h2>Родительский <br/> контроль</h2>
            <div className="parental__control_block_services">
                <label className="toggle">
                    <span className="toggle-label">Родительский контроль</span>
                    <input className="toggle-checkbox" type="checkbox"
                           checked={checkedParental}
                           onChange={(e) => {
                               console.log(e.target.checked)
                               setCheckedParental((state) => !state)
                           }}
                    />
                    <div className="toggle-switch"></div>
                </label>
                <label className="toggle">
                    <span className="toggle-label">Защита PIN</span>
                    <input className="toggle-checkbox" type="checkbox"
                           checked={checkedPin}
                           onChange={(e) => {
                               console.log(e.target.checked)
                               setCheckedPin((state) => !state)
                           }}
                    />
                    <div className="toggle-switch"></div>
                </label>
                <button onClick={() => setActive(() => !active)}>
                    Установить автоматическое <br/> Включение/Отключение
                </button>
                <p>
                    Дети пользуются интернетом – вы контролируете.
                    <br/>
                    Даже если находитесь не дома!
                    <br/>
                    С помощью «Родительского контроля» вы можете удаленно управлять доступом в интернет по адресу: {elem.currentAddress}.
                    <br/>
                    Включение/отключение услуги Родительский контроль никак не влияет на начисления за интернет-тариф.
                </p>

            </div>
            <div className={`parental__control_block_bottom ${active ? 'active' : ''}`}>
                <div className="first_row">
                    <img className="arrowLeft" alt='Вернуться' onClick={() => setActive(() => !active)} src={arrowLeftSVG}/>
                    <h3>Автоматическое вкл/выкл</h3>
                </div>
                <div className="second_row">
                    <TimeBlock/>
                    <button
                        onClick={(e)=> {
                            if(stateList.length < 4) {
                                const data =  {
                                    time_1: '10:00',
                                    time_2: '10:00',
                                    days: [],
                                    status: false
                                }

                                setStateList(prevState => [...prevState, data])
                                setSelectedList(stateList.length);
                                setChecked(state => !state);
                            }
                            else {
                                e.target.innerHTML = 'Максимум 4 шаблона'
                                setTimeout(() => {
                                    e.target.innerHTML = 'Добавить'
                                }, 2000)
                            }
                        }
                    }
                    >Добавить</button>
                </div>
            </div>
            <div className={`parental__control_modal ${!checked ? '' : 'active'}`}>
                <div>
                    <span>Время вкл</span>
                    <span>Время выкл</span>
                </div>
                <div>
                    <Time/>
                </div>
                <div>
                    <GetBtnDay/>
                </div>
                <div>
                    <button onClick={()=> {
                        setChecked(state => !state);
                        changeTime(true);
                    }}>Сохранить</button>
                    <button id='button_delete' onClick={()=> {
                        const newState = [];
                            stateList.map((item, index) => {
                                if (index !== selectedList) {
                                    newState.push(item)
                                }
                            })
                        setStateList(newState)
                        setChecked(state => !state);
                        changeTime(true, newState)
                    }}>Удалить</button>
                </div>
            </div>
            <div className={`background ${checked ? 'active' : ''}`}></div>
        </section>
    )
}