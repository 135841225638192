import { configureStore } from "@reduxjs/toolkit";

// import profileSlice from "../../components/Profile/ProfileSlice";
import personalAccountSlice from "../../components/PersonalAccount/PersonalAccountSlice"
import paymentSlice from "../../components/Payment/PaymentSlice";
import parentalControlSlice from "../../components/ParentalControl/ParentalControlSlice";
import intercomEventsSlice from "../../components/IntercomEvents/IntercomEventsSlice"


const store = configureStore({
    reducer: { personalAccountSlice, paymentSlice, parentalControlSlice, intercomEventsSlice},
    middleware: getDefaultMiddleware => getDefaultMiddleware(),
    devTools: process.env.NODE_ENV !== 'production'
});

export default store;